import { getTotalHeight } from '../../utils/get_total_height'

/**
 * Adjusts the height of a container element based on the cumulative height of its child elements.
 *
 * @param {HTMLElement} contentElements - The container element whose height will be adjusted.
 */
function showNumberElements(contentElements) {
  const numberVisibleElements = contentElements.getAttribute('data-n-visible-elements')

  if (!numberVisibleElements) return
  const elementWithDataHeight = Array.from(contentElements.querySelectorAll('[data-height]'))

  // Check if the data-height has not yet been loaded. Ex: In modal
  if (!elementWithDataHeight || elementWithDataHeight.length <= numberVisibleElements) return

  let heightTotal = 0

  for (let i = 0; i < numberVisibleElements; i++) {
    heightTotal += getTotalHeight(elementWithDataHeight[i])
  }

  // Mystery. Always over 1px, so it is subtracted.
  contentElements.style.height = `${heightTotal - 1}px`
  contentElements.setAttribute('data-total-height', heightTotal - 1)
}

export function init(el) {
  const elements = el ? el : document.querySelectorAll('.js-is-truncable')

  if (!elements.length) return

  elements.forEach(elementTruncable => {
    const elementTruncated = elementTruncable.querySelector('.js-truncated')
    // check if element has content
    if (!elementTruncated || elementTruncated.textContent.trim().length < 300) {
      return
    }

    showNumberElements(elementTruncated)

    const toggleViewMoreButton = elementTruncable?.querySelector('.js-link-see-more')

    elementTruncated.classList.add('is-truncable')

    toggleViewMoreButton?.classList.remove('hidden')
    toggleViewMoreButton?.querySelector('.js-close').classList.add('hidden')

    toggleViewMoreButton?.addEventListener('click', function (e) {
      e.preventDefault()

      elementTruncated.classList.toggle('is-truncable')

      if (!elementTruncated.classList.contains('is-truncable')) {
        toggleViewMoreButton.classList.add('l-up')
        if (elementTruncable.classList.contains('opinion')) {
          toggleViewMoreButton.querySelector('.js-close').classList.remove('hidden')
          toggleViewMoreButton.querySelector('.js-more').classList.add('hidden')
        }
      } else {
        toggleViewMoreButton.classList.remove('l-up')
        if (elementTruncable.classList.contains('opinion')) {
          toggleViewMoreButton.querySelector('.js-close').classList.add('hidden')
          toggleViewMoreButton.querySelector('.js-more').classList.remove('hidden')
        }
      }

      // REB - Truncate div according to number of elements
      if (elementTruncated.getAttribute('data-total-height')) {
        if (elementTruncated.style.height !== '100%') {
          elementTruncated.style.height = '100%'
        } else {
          const totalHeight = elementTruncated.getAttribute('data-total-height')
          elementTruncated.style.height = `${totalHeight}px`
        }
      }
    })
  })
}

window.IB.truncable = {
  init,
}

IB.truncable.init()
