import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { setCookie } from '../../../utils/cookie_manager'

dayjs.extend(customParseFormat)

export const EVENT_SESSION_LOGOUT = 'session:logout'
const eventSessionLogout = new Event(EVENT_SESSION_LOGOUT)

let not_browsing_session_data = null
let last_time_write = null
const greetings = document.querySelector('.js-greetings-popup')
let initialized = false
let callbacks = []

function notBrowsingSessionData() {
  return not_browsing_session_data
}

function checkLocalStorage() {
  try {
    const uid = new Date()
    localStorage.setItem(uid, uid)
    localStorage.removeItem(uid)
    return true
  } catch (exception) {
    return false
  }
}

function saveData(res) {
  if (checkLocalStorage()) {
    localStorage.setItem('browsing_session', JSON.stringify(res))
  } else {
    not_browsing_session_data = JSON.stringify(res)
  }
}

function getData() {
  if (checkLocalStorage()) {
    return JSON.parse(localStorage.getItem('browsing_session'))
  }
  return JSON.parse(notBrowsingSessionData())
}

function isUserLogged() {
  const ibProLogged = window.IB.visitor_type_detector.is_agency()

  if (ibProLogged) {
    return true
  }
  const storage = JSON.parse(localStorage.getItem('browsing_session'))
  const storageData = storage ? JSON.parse(localStorage.getItem('browsing_session')).redis_values : {}
  const isLoggedIn = storage ? storageData.logged_in === 'true' : false

  return isLoggedIn
}

function clearData() {
  if (checkLocalStorage()) {
    localStorage.removeItem('browsing_session')
    //setCookie('logged_messages', false, '-1')
    IB.cookieManager.deleteCookies(['logged_messages'])
    document.dispatchEvent(eventSessionLogout)
  } else {
    not_browsing_session_data = null
  }
}
function getCookie(data) {
  const $link = $('.js-user-options-link')
  const $popup = $('.js-user-options')

  if (!$link.length && !$popup.length) return
  if (data === undefined) {
    $link.attr('href', $link.data('log-in-url'))
  } else if (data.redis_values.logged_in !== undefined) {
    // if user is logged in, add classes and remove link (vue menu)
    $link.closest('li').addClass('dropdown-link js-user-options-dropdown-link')
    $link.attr('href', '#')
    $link.removeAttr('data-remote')
  } else {
    // if user is not logged in, set login url
    $link.attr('href', $link.data('log-in-url'))
  }
}

function executeCallbacks() {
  $.each(callbacks, function (_, c) {
    c()
  })
  callbacks = []
}

function addGreetings(data) {
  const logged_in = data.redis_values.logged_in
  if (logged_in !== undefined) {
    greetings.classList.add('start-animation')
  }
}

function setNameToDiv(name, $div, dataAttr, truncate) {
  if ($div.length && $div.data(dataAttr) && $div.data(dataAttr) !== '') {
    if (truncate) {
      name = name.split(' ')[0] // Only show the first name on compound names
    }
    const message = $div.data(dataAttr).replace('{{name}}', name)
    $div.html(message)
  }
}

function setLevelToDiv(level, $div, dataAttr) {
  if ($div.length && $div.data(dataAttr) && $div.data(dataAttr) !== '') {
    const message = $div.data(dataAttr).replace('{{level-text-number}}', level)
    $div.html(message)
  }
}

function horizons_level(level) {
  var level_text = ''
  switch (level) {
    case '2':
      level_text = 'Sand'
      break
    case '3':
      level_text = 'Ocean'
      break
    case '4':
      level_text = 'Sky'
      break
    case '5':
      level_text = 'Infinity'
  }
  return level_text
}

function setUserImage(image_url, name) {
  const data = JSON.parse(localStorage.getItem('browsing_session'))
  const logged_in = data !== null ? data.redis_values.logged_in : null

  document.querySelectorAll('[data-user-image-with-letter]').forEach(el => {
    const $user_letter = $(el)
    if (image_url) {
      $user_letter.closest('.user-image').addClass('user-image-with-avatar').removeClass('user-image-with-letter')
      $user_letter.closest('.user-image').html(`<img class="s s-person2" alt="Avatar" src="${image_url}">`)
    } else {
      if (name) {
        $user_letter.html(name.charAt(0))
        $user_letter.closest('.user-image').addClass('with-name').find('.user-image-letter').removeClass('s s-person2')
      } else {
        $user_letter.closest('.user-image').addClass('without-name')
      }
      $user_letter.closest('.user-image').addClass('user-image-flex')
    }
    if (IB.currentDevice === 'mobile') {
      if (logged_in) {
        $('.mobile-header .mobile-user .person-icon').hide()
        $('.mobile-header .mobile-user .js-image-test').hide()
        $('.info-user-container .js-image-test').hide()
      }
      if (IB.currentPageType.isHorizons()) {
        $('.mobile-header .mobile-user .person-icon').hide()
      }
    } else if (IB.currentDevice === 'tablet') {
      $('.main-header .mobile-user .person-icon').hide()
    } else {
      $('.js-user-options-link .person-icon').hide()
    }
  })
}

function personalizeMenu(name) {
  if (name) {
    const $name = $('.header-mobile-menu').find('.name')
    $name.text(name)
    $('.header-mobile-menu.logged').show()
    $('.header-mobile-menu.logout').hide()
  }
}

function setHrefToLink(link, $div) {
  if (link !== undefined) {
    if (link.length) {
      $div.attr('href', link).show()
    }
  } else {
    $div.hide()
  }
}

function mobileMenu(data) {
  const $logged_menu = $('.mobile-menu').find('.user-menu')

  // LOGIN
  if ($logged_menu) {
    if (data !== null && data.redis_values.name !== undefined) {
      $logged_menu.removeClass('hidden')
    }
  }
}

//Moved from app/frontend/src/layout/mobile_header/mobile_header.js
function mobileHeader() {
  if (isUserLogged() && $('.mobile-header').length) {
    const $user_link = $('.mobile-header').find('.my-iberostar-link')
    $user_link.attr('href', $user_link.data('mibe'))
    $user_link.removeAttr('data-remote')
  }
}

function personalizeInterface(data) {
  if (data !== null) {
    const truncate = data.redis_values.name || $.cookie('agency_user_web_name')
    const name =
      data.redis_values.name ||
      $.cookie('agency_user_web_name') ||
      $('.user-options-link-wrapper .name').attr('data-name-soft')
    const destination_url = data.redis_values.destination_url
    const last_hotel_url = data.redis_values.last_hotel_url
    const level = data.redis_values.level
    const horizons_level_text = horizons_level(data.redis_values.level)
    const logged_in = data.redis_values.logged_in || $.cookie('agency_user_web_name')
    if (logged_in !== undefined) {
      setLevelToDiv(horizons_level_text, $('.level-text-number'), 'level-text-number')
      let greetings_title_message = 'greetings-title-message'
      // If the user doesn't have name, only shoy the welcome text without the greeting
      if (typeof name === 'undefined') {
        greetings_title_message = 'name'
      }
      setNameToDiv(name, $('.greetings-title'), greetings_title_message, truncate)
      setNameToDiv(name, $('.logout-are-you'), 'logout-message', truncate)
      setNameToDiv(name, $('.js-user-options-link .name'), 'name', truncate)
      setNameToDiv(name, $('.mobile-user .mobile-button-text'), 'name', truncate)
      setUserImage(data.redis_values.image_url, truncate)
      personalizeMenu(name)
      $('a.user-options-link-wrapper').removeClass('utag-ib-register')
      $('.js-login-url .link').hide()
    } else {
      $('a.user-options-link-wrapper').addClass('utag-ib-register')
    }

    setHrefToLink(destination_url, $('a.mx-last-destination'))
    setHrefToLink(last_hotel_url, $('a.mx-last-hotel'))
    if (level !== undefined && $.cookie('logged_messages') !== `true:${level}`) {
      // Set the logged_messages cookie
      setCookie('logged_messages', `true:${level}`)
    }
    mobileMenu(data)
    mobileHeader()
  }
}

function store(key, value) {
  $.ajax({
    dataType: 'JSON',
    type: 'POST',
    url: '/api/v3/browsing_sessions/store/',
    data: {
      key,
      value,
    },
  })
}

function storeHash(hash, callback) {
  $.ajax({
    dataType: 'JSON',
    type: 'POST',
    url: '/api/v3/browsing_sessions/store/',
    data: {
      hash,
      return_key_only: false,
    },
  })
    .success(function (data) {
      if (callback !== undefined) {
        callback(data)
      }
    })
    .error(function (data) {
      console.log(data)
      // Parece algún problema con el nginx de IBP
      let fixed_response = null
      try {
        fixed_response = JSON.parse(`${data.responseText}}}`)
      } catch (e) {
        try {
          fixed_response = JSON.parse(`${data.responseText}]"}}`)
        } catch (e) {
          try {
            fixed_response = JSON.parse(`${data.responseText}"]"}}`)
          } catch (e) {}
        }
        fixed_response = null
      }

      if (callback !== undefined) {
        callback(fixed_response)
      }
    })
}

function setTimestamp() {
  const current_date = new Date()
  if (checkLocalStorage()) {
    localStorage.setItem('last_time_write', current_date)
  } else {
    last_time_write = current_date
  }
}

function cleanTimestamp() {
  if (checkLocalStorage()) {
    localStorage.removeItem('last_time_write')
  } else {
    last_time_write = null
  }
}

function checkTimestamp(callback) {
  const current_date = new Date()
  last_time_write = localStorage.getItem('last_time_write')

  if (last_time_write === null) {
    callback()
    setTimestamp()
  } else if (current_date > dayjs(new Date(last_time_write)).add('1', 'hour')) {
    callback()
    setTimestamp()
  }
}

function get(callback, callback2) {
  if ($.cookie('remove_browsing') !== undefined) {
    clearData()
    setCookie('remove_browsing', false, -1)
  }
  if ($.cookie('clean_timestamp') !== undefined) {
    cleanTimestamp()
    setCookie('clean_timestamp', false, -1)
  }
  if ($.cookie('browsing_session') !== undefined) {
    if (localStorage.getItem('browsing_session') === null) {
      $.ajax({
        dataType: 'JSON',
        type: 'GET',
        url: '/api/v3/browsing_sessions/',
      })
        .success(function (data) {
          saveData(data)
          callback(data)
          if (typeof callback2 === 'function') callback2()
        })
        .error(function (data) {})
    } else {
      callback(localStorage.getItem('browsing_session'))
      if (typeof callback2 === 'function') callback2()
    }
  } else {
    clearData()
    getCookie()
    personalizeInterface({ redis_values: 'undefined', redis_key: 'undefined' })
    if (typeof callback2 === 'function') callback2()
  }
}

function getUserLevel() {
  const storage = JSON.parse(localStorage.getItem('browsing_session'))
  const storageData = storage ? JSON.parse(localStorage.getItem('browsing_session')).redis_values : {}
  return storage && storageData && storageData.level ? +storageData.level : 1
}

function isUserLevelSamePageLevel(data) {
  let level = 0
  try {
    level = data.redis_values.level
  } catch (exception) {
    console.log('isUserLevelSamePageLevel error')
  }
  const view_level = $('body').data('constrained-view-level')

  // If there are any data that does not exists asume the level is the same
  return (
    typeof view_level === 'undefined' ||
    view_level === 0 ||
    level === 0 ||
    typeof level === 'undefined' ||
    level.toString() === view_level.toString()
  )
}

function force_browser_to_reload() {
  let url = window.location.href
  const cache_param = `cache=${Date.now()}&${Date.now()}`
  const params = url.split('?')
  if (params.length > 1) {
    url += '&'
  } else {
    url += '?'
  }
  if (window.location.href.indexOf('cache=') === -1) {
    window.location.assign(url + cache_param)
  }
}

function readRedis(callback) {
  if (callback !== undefined) {
    callbacks.push(callback)
  }

  if (!initialized) {
    get(
      function (data) {
        if (typeof data === 'string') {
          data = JSON.parse(data)
        }
        if (data !== undefined) {
          if (!isUserLevelSamePageLevel(data)) {
            force_browser_to_reload()
          }
          personalizeInterface(data)
          checkTimestamp(function () {
            addGreetings(data)
          })
          getCookie(data)
        }
        initialized = true
      },
      function () {
        executeCallbacks()
      }
    )
  } else {
    executeCallbacks()
  }
}

window.IB.browsing_sessions = {
  readRedis,
  clearData,
  getData,
  saveData,
  isUserLogged,
  getUserLevel,
  setUserImage,
  store,
  storeHash,
}

document.addEventListener('DOMContentLoaded', () => {
  IB.browsing_sessions.readRedis()
})

export { getUserLevel }
